//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//
// Black color
$black:    											#000000 !default;

// White color
$white:    											#ffffff !default;

// // Gray colors
$gray-100: 											#F3F6F9 !default;
$gray-200: 											#EBEDF3 !default;
// $gray-300: 											#E4E6EF !default;
// $gray-400: 											#D1D3E0 !default;
$gray-500: 											#B5B5C3 !default;
// $gray-600: 											#7E8299 !default;
// $gray-700: 											#5E6278 !default;
// $gray-800: 											#3F4254 !default;
$gray-900: 											#181C32 !default;

// // Dark colors
$dark:          									$gray-900 !default;
// $dark-75:          									$gray-800 !default; // Custom variable
// $dark-65:          									$gray-700 !default; // Custom variable
// $dark-50:          									$gray-600 !default; // Custom variable
// $dark-25:          									$gray-400 !default; // Custom variable
// Theme colors
// Primary
$primary:       									if(isDarkMode(), #3699FF, #009EF7);
$primary-active:    								if(isDarkMode(), #187DE4, #0095E8);
$primary-light:    								    if(isDarkMode(), #212E48, #F1FAFF);
$primary-inverse:  									#FFFFFF;

// Success
$success:       									if(isDarkMode(), #0BB783, #50CD89);
$success-active:    								if(isDarkMode(), #04AA77, #47BE7D);
$success-light:    									if(isDarkMode(), #1C3238, #E8FFF3);
$success-inverse:  									#FFFFFF;

// Info
$info:       									    if(isDarkMode(), #8950FC, #7239EA);
$info-active:    									if(isDarkMode(), #7337EE, #5014D0);
$info-light:    									if(isDarkMode(), #2F264F, #F8F5FF);
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    if(isDarkMode(), #F64E60, #F1416C);
$danger-active:    									if(isDarkMode(), #EE2D41, #D9214E);
$danger-light:    									if(isDarkMode(), #3A2434, #FFF5F8);
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									if(isDarkMode(), #FFA800, #FFC700);
$warning-active:    								if(isDarkMode(), #EE9D01, #F1BC00);
$warning-light:    									if(isDarkMode(), #392F28, #FFF8DD);
$warning-inverse:  									#FFFFFF;

// Text muted
$text-muted:                  						$gray-500 !default;

// Table
$table-bg:                      					transparent !default;
// $table-accent-bg:               					$gray-200 !default;
// $table-hover-bg:                					$gray-300 !default;
// $table-active-bg:               					$table-hover-bg !default;

$table-border-color:            					$gray-200 !default;
$table-head-bg:                 					$gray-100 !default;
// $table-head-color:              					$dark-75 !default;
$table-head-font-size:             					1rem !default; // Custom variable
$table-head-font-weight:           					600 !default; // Custom variable

$table-dark-color:            						$white !default;
$table-dark-bg:               						$dark !default;
$table-dark-accent-bg:        						rgba($white, .05) !default;
$table-dark-hover-color:      						$table-dark-color !default;
$table-dark-hover-bg:        						rgba($white, .075) !default;
$table-dark-border-color:     						lighten($table-dark-bg, 7.5%) !default;

$table-head-bg:                                     $gray-100 !default; // Custom variable
$table-compact-head-font-size:                      0.9rem !default; // Custom variable
$table-compact-head-color:                          $text-muted !default; // Custom variable
$table-compact-head-text-transform:                 uppercase !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-letter-spacing:                 0.1rem !default; // Custom variable