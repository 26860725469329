@each $name, $value in $theme-colors{
    .styled-table-#{$name} {
        width: 100%;
        border-collapse: collapse;
        margin: 25px 0px;
        font-size: 0.9em;
        font-family: sans-serif;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
    .styled-table-#{$name} thead tr {
        background-color: $value !important;
        color: #ffffff;
        text-align: left;
    }
    .styled-table-#{$name} th,
    .styled-table-#{$name} td {
        padding: 12px 15px;
    }
    .styled-table-#{$name} tbody tr {
        border-bottom: 1px solid #dddddd;
    }
    
    .styled-table-#{$name} tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }
    
    .styled-table-#{$name} tbody tr:last-of-type {
        border-bottom: 2px solid theme-inverse-color($name);
    }
    .styled-table-#{$name} tbody tr.active-row {
        font-weight: bold;
        color: $value !important;
    }
}