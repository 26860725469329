.loading-modal-mass{
    .ant-modal-content{
        position: relative;
        background-color: rgba(245, 238, 238, 0) !important;
        border: 0;
        background-clip: padding-box;
        box-shadow: none !important;
        width: 500px;
    }

    .ant-modal-body{
        padding: 0 !important;
        font-size: 0 !important;
        line-height: 1 !important;
    }
}