:root {
    --yellow: #feb60a;
    --red: #ff0062;
    --blue: #00dbf9;
    --violet: #da00f7;
}
// body {
    //     margin: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     height: 100vh;
    //     background-color: #1a1940;
    //     background-image: linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,153,212,0) calc(15% + 100px), rgba(0,99,138,0) calc(85% + 100px), rgba(0,0,0,0.15) 100%);
    // }
    
    div.pre-loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.pre-loader > div {
        width: 3vw;
        height: 3vw;
        border-radius: 100%;
        margin: 2vw;
        background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
        //animation: effect 1.5s 0.5s linear infinite;
    }
    
    .yellow {
        background-color: var(--yellow);
        animation: effect 1.5s 0.1s linear infinite;
    }
    
    .red {
        background-color: var(--red);
        transition-delay: 100ms !important;
        animation: effect 1.5s 0.2s linear infinite;
        
    }
    
    .blue {
        background-color: var(--blue);
        transition-delay: 200ms !important;
        animation: effect 1.5s 0.3s linear infinite;
        
    }
    
    .violet {
        background-color: var(--violet);
        transition-delay: 300ms !important;
        animation: effect 1.5s 0.4s linear infinite;
        
    }
    
    @keyframes effect {
        0%, 50%, 100% {
            transform: scale(1);
            filter: blur(0px);
        }
        25% {
            transform: scale(0.6);
            filter: blur(3px);
        }
        75% {
            filter: blur(3px);
            transform: scale(1.4);
        }
    }